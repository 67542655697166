@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.divDashboardRoot{
  
  background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.3) -29.25%, rgba(255, 200, 172, 0.2) 15.7%, rgba(253, 222, 207, 0) 84.2%);;
  height: 100%;
  width: 100%;
  margin: 0px;
  overflow: hidden;
}
.dashboard_header {
  .header_title {
    font-size: 32px;
    margin-left: 11rem;
    margin-top: 6rem;
    color: #1E1D2D;
  }
  .header_subtitle {
    font-weight: 300;
    font-size: 16px;
    margin-left: 11rem;
    color: #1E1D2D;
  }
}

.batch_listContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 80%;
  align-items: center;
  overflow-y: scroll;
  height: 54vh;
  margin: 0 auto;
  margin-top: 2rem;
  .list_card {
    background: #FFFFFF;
    display: flex;
    padding: 2rem;
    box-shadow: 0px 4px 8px rgb(182 182 182 / 25%);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    height: 76px;
    border: 1px solid #FFD7C4;
    border-left: 9px solid #DE6B32;

    .download_btn {
      background: #DE6B32!important;
      color: $white !important;
      border-color: #DE6B32 !important;
      // width: 9rem;
    }
    .pending_btn {
      background: $greyLight !important;
      color: $black !important;
    }
  }
}
