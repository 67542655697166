@import "./variable.scss";

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex-spaceBetween {
  display: flex;
  justify-content: space-between;
}

@mixin flex-justifyCenter {
  display: flex;
  justify-content: center;
}
@mixin link-text {
  color: $primary;
  border-bottom: 1px solid $primary;
  padding-bottom: 1px;
  cursor: pointer;
}

 

@mixin flex-column {
  flex-direction: column;
  display: flex;
}

@mixin spacing-center-content {
  display: flex;
  justify-content: center;
  gap: 10px;
}

//breakpoint
@mixin breakpoint($point) {
  @if $point==xlgdesktop {
    @media (max-width: 1700px) {
      @content;
    }
  } @else if $point==lgdesktop {
    @media (max-width: 1600px) {
      @content;
    }
  } @else if $point==mldesktop {
    @media (max-width: 1500px) {
      @content;
    }
  } @else if $point==mddesktop {
    @media (max-width: 1368px) {
      @content;
    }
  } @else if $point==desktop {
    @media (max-width: 1280px) {
      @content;
    }
  } @else if $point==smdesktop {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point==tablet {
    @media (max-width: 991px) {
      @content;
    }
  }
}

@mixin respond-to($breakpoint) {
	@if $breakpoint == mobile {
	  @media (max-width: 767px) {
		@content;
	  }
	} @else if $breakpoint == tablet {
	  @media (min-width: 768px) and (max-width: 1023px) {
		@content;
	  }
	} @else if $breakpoint == desktop {
	  @media (min-width: 1024px) and (max-width: 1439px) {
		@content;
	  }
	}
  }
  