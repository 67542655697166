.try-btn{
       /* width: 168px; */
       height: 52px;
       border-radius: 8px;
       border: none;
       outline: none;
       background-color: #CA612E;
       color: #FCF0EB;
       font-size: 14px;
       line-height: 21px;
       font-weight: 700;
       /* padding: 2rem 2rem; */
       display: flex;
       /* justify-content: center; */
       align-items: center;
       padding-left: 3rem;
       padding-right: 4rem;
    .wrapper {
      position: absolute;
      overflow: hidden;
      font-size: 30px;
      font-weight: 800;
      width: 30px;
      margin-left: 2px;
      .slide-1 {
          position: relative;
          right: 0px;
          transition: 1.5s;
      }
      
      .slide-2 {
          position: relative;
          left: -200%;
          transition: 1.5s;
      }
  }
  &:hover{
      background-color: #251F17;
    color: #eaeaea !important;
    .slide-2 {
      transition: 1.5s;
      left: -90%; 
  }
  .slide-1 {
      transition: 1.5s;
      right: -100%;
  }
  }
}   
