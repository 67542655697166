@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.form_batch {
  margin-top: 3rem;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;

  .input_batch {
    label {
      font-weight: 400;
      font-size: 16px;
      color: $greyMidLight;
    }
    .name_input {
      padding: 8px 12px;
      width: 528px;
      height: 40px;
      background: $white;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }
}
