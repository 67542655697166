@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";
   // margin-top: 5rem;

  .email_content {
    width: 56.2%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
     margin-top: 3rem;
  }



