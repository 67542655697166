@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.modal1 {
  .ant-modal-content {
    background-color: #FFFFFF !important;
    border: 1px solid #DE6B32 !important;
    height: 90vh;
    padding: 0px !important;
  }
}
