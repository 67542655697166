.header-section{
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 60px;
    background-color: #fff;
    width: 100%;
    // position: fixed;
    bottom: 0;
    left: 0;
     .header-container{
         width: 94%;
        height: 100%;
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo{
            font-size: 25px;
            font-weight: 700;
            line-height: 42.91px;
            color: #DE6B32;
            span{
                color: #212121;
            }

        }
        .login-section{
            display: flex;
            gap: 10px;
            .btn{
                width: 112px;
                border-radius: 8px;
                background-color: #FCF0EB;
                font-size: 14px;
                font-weight: 700;
                line-height: 21px;
                text-align: center;
                color: #CA612E;
                opacity: 90%;
                border: none;
                outline: none;
                &:hover{
                    
                }
            }
            .signIn-btn:hover{
                transition: 0.5s;
                background-color: #CA612E;
                    color: #FCF0EB;
            }
            .signUp-btn{
                    background-color: #CA612E;
                    color: #FCF0EB;
                    &:hover{
                        background-color: #212121;
                        color: #fff;
                        transition: 0.5s;
                    }
            }
        }
    }

}