@import "../../assets/scss/variable.scss";
// .ant-btn-primary:hover {
//   background: $primary !important;
//   // border-color: $primary !important;
//   // color: $white !important;
//   // width: 100%;
// }
// .ant-btn-default {
//   border-color: $primary !important;
//   color: $primary !important;
// }
// .ant-btn-default:focus,
// .ant-btn-default:hover {
//   color: $primary !important;
// }
.default-btn {
  span {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
  }

  .btn-rigthIcon {
    margin-left: 1vw;
  }
  .btn-leftIcon {
    margin-right: 0.5vw;
  }
}
.ant-btn[disabled], .ant-btn[disabled]:active, .ant-btn[disabled]:focus, .ant-btn[disabled]:hover {
    color: rgba(0,0,0,.25) !important;
    border-color: #d9d9d9 !important;
}
.ant-btn[disabled], .ant-btn-primary[disabled]{
    color: rgba(0,0,0,.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
}

 