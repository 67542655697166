@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";
 .stepper_main{
    // margin-top: 6vh;
    // height: 90%;
    width: 25% !important;
    // padding: 2vh 7vw;
    // box-shadow: 0px 0px 6px -2px #0000004d;
    border-radius: 16px;
    background-color: #ffffff;
    .stepImg{
        width: 1em;
        height: 1em;
    }
    
    .cursor{
        cursor:pointer;
    }
    
    .disabledCursor{
        cursor: default;
    }
    .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
        background-color: $greyLightMid !important;
        // width: 45px !important;
    }
    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after{
        background-color:  #DE6B32 !important;
    }
    
    .ant-steps-item-finish .ant-steps-item-icon{
        border-color:  #DE6B32 !important;
    }
    
    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon{
        color:  #DE6B32 !important;
    }
    
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon{
        background: #DE6B32 !important;
    }
    
    .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path{
        stroke:#DE6B32 !important ;
    }
    .ant-steps-item-title::after{
        background-color: #d1d1d1bf !important;
    }
 }

