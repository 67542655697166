@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.batchRootPage{
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.3) -29.25%, rgba(255, 199, 172, 0.2) 15.7%, rgba(253, 222, 207, 0) 84.2%);;
  // border:1px solid #DE6B32;
  height: 90.4%;
}

.batch-form {
  .step-container {
    width: 65%;
    margin: 0 auto;
    margin-top: 1rem;
    display: flex;

    .batch_process {
      // width: 45%;
      margin: 0 auto;
    }
     
  }
}

.batch_header {
  display: flex;
  // background: red;
  justify-content: space-between;
  padding: 0 13rem;
  align-items: center;
  margin-top: 4rem;

  .batch_content {
    .header_main {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;

      .banner-heading-main {
        font-size: 28px;
        // margin: 0 auto;
        // margin-top: 2rem;
      }

      .header-subHeading {
        font-style: normal;
        font-weight: 4300;
        font-size: 16px;
        color: #1E1D2D;;
        margin: 0 auto;
      }
    }
  }
}
