.container_left {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 2rem 0;
  .header_top {
    width: 62%;
    .description {
      font-size: 12px;
      font-weight: 300;
    }
    .flix {
      font-weight: bold;
      color: #de6b32;
      .plus {
        color: black;
      }
    }
  }

  .form {
    padding: 30px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    box-sizing: border-box;
    .ant-form-vertical .ant-form-item-row {
      flex-direction: row !important;
    }
    .ant-form-item-label label {
     
      font-size: 17px !important;
      font-weight: 300;
    }

    .password-item {
      margin: 0;
    }

    .forgot-pass {
      display: flex;
      justify-content: end;
      padding: 10px;
    }

    .connect-with-google {
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      border-radius: 7px;
      background-color: #ececec;
      &:hover {
        background-color: #ececec;
        color: black;
      }
      .google-icon {
        width: 24px !important;
        height: 24px !important;
      }
    }

    .connect-with-google-item {
      margin: 0rem 0rem 1rem 0rem;
    }

    .divider-item {
      margin: 0;
    }

    .divider {
      font-size: small;
      color: grey;
    }

    .space {
      height: 2.5rem;
    }

    .submit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: #de6b32;
      &:hover {
        background-color: #de6b32;
      }
    }

    .sign-in {
      margin-bottom: 0px;
    }

    .grey {
      color: rgba(170, 170, 170, 1);
    }

    .create-account {
      font-weight: 700;
      color: #de6b32;
      text-decoration: none;
    }

    .submit {
      height: 2.5rem;
    }
    .already-have {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      gap: 5px;
  }
    
  }
}
.container_right {
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    object-fit: contain;
    position: absolute;
    top: 20px;
    // height: 90vh;
  }
}
