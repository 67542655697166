.download_image {
  padding: 0 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
  display: grid;
  margin-top: 1rem;
  .download_imageCard {
    background: white;
    width: 200px;
    // height: 196px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;

    .card_header {
      display: flex;
      align-items: center;
      flex-direction: column;

      .subText {
        font-size: 8px;
      }
    }
    .card_footer {
        span {
            font-size: 8px;
        }
    }
  }
}
