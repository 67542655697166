.backgroundImageBlock
{
    width: 100%;
    height: 18vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid red;
    
    .configBlock{
        width: 100%;
        height: 20%;
        // border: 1px solid orange;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .ant-select-single .ant-select-selector
        {
            border-radius: 0px !important;
        }
    }

    .uploadSection{
        width: 100%;
        height: 70%;
        border: 1px solid #D4D4D4;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        input[type="file"] {
            display: none;
        }

        .closeButton{
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }

        .fitImage{
            width: 100%;
            height: 80%;
            object-fit: contain;    
            // border: 1px solid #000;
        }

        .pointerCursor{
            cursor: pointer;
        }
    }

    
}