@import "../../assets/scss/mixin.scss";
.convert-section {
  position: relative;
  .convert-container {
    width: 88%;
    margin: 0 auto;
    .heading {
        font-weight: 700;
        font-size: 38px;
        line-height: 24px;
        text-align: center;
        font-family: Inter;
        color: #251F17;
        margin-top: 1rem;
        padding-left: 43px;
      span {
        color: #ca612e;
      }
    }
    .heading-img {
        // position: relative;
        // bottom: 45px;
        // left: 7%;
        // object-fit: cover;
    }
    .heading-text {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      font-family: Inter;
      color: #544e47;
      margin-top: 1.5rem;
      padding-left: 45px;

    //   padding-left: 6rem;
    }
    .converting-steps {
      display: flex;
      /* justify-content: space-evenly; */
      margin-top: 2rem;
      /* margin: 0 auto; */
      /* align-items: center; */
      justify-content: center;
      gap: 10rem;
      .item {
        // width: 278px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        .item-img {
          img {
            width: 91px;
            aspect-ratio: 1/1;
            object-fit: cover;
          }
        }
        .item-heading {
          font-family: GT Super Txt Trial;
          font-weight: 700;
          font-size: 24px;
          line-height: 36.4px;
          color: #1e1e1e;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .item-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #544e47;
        }
      }
    }
  }
}

@include respond-to(desktop) {
  .convert-section {
    .convert-container {
      .heading {
        font-size: 34px;
      }
      .heading-img {
        // position: relative;
        // bottom: 45px;
        // left: 1px;
        img {
          width: 100px;
        }
      }
    }
  }
}
