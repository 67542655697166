@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&family=Roboto:wght@300;500;700;900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  height: 100%;
  width: 100%;
  max-width: 100%;
  /* background-color: #313131 !important; */
}
body{
  height: 100%;
  width: 100%;
  max-width: 100%;
  scrollbar-width: thin !important;
  /* background-color: #313131 !important; */
}

.main{
  width: 100%;
  /* height: 100vh;  */
} 

/* .ant-collapse-header-text
{
  color: white !important;
} */
.ant-collapse-header{
  color: #5F5F5F !important;
}

.ant-collapse-content-box
{
  background-color: transparent !important;
  
  border: none !important;
}

.ant-collapse-content
{
  background-color: transparent !important;
}

.ant-form-item-label label{
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 22px !important;
}


.ant-collapse
{
  background-color: #FFFFFF;
}

#backgroundColorChange div[title='TRANSPARENT']{
  background-image : url('./assets/images/Trans_Grid1.png') !important;
  }