.clipImage
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    .containerBlock{
        width: 40%;
        // border: 1px solid red;
        height: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .imageClass{
        width: 100%;
        height: 80%;
        object-fit: contain;
    }
    .imageTitle{
        width: 100%;
        height: 20%;
        color: #5F5F5F;
        font-size: 0.75rem;
        text-align: center;
    }
}

