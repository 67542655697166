.leftSectionPoster {
	background: #fcf9ef;
	position: relative;
}
.posterHeader {
	font-size: 48px;
	font-weight: 700;
	/* width: 85%; */
}
.redLabel {
	color: #f85479;
	font-size: 17px;
	font-family: Inter;
	padding-left: 3px;
	font-weight: 600;
}
.rearCover {
	position: absolute;
	z-index: 999;
	bottom: 0px;
}
.fullWidthHeight {
	width: 100%;
}
.rearImageContainer {
	background-repeat: no-repeat;
	background-position-y: bottom;

	background-size: contain;
	flex-direction: column-reverse;
	display: flex;
	width: 19%;
}
.maxHeightWidth {
	max-width: 146.27px;
	max-height: 203.6px;
}
.mainPosterLeft {
	display: flex;
	height: 550px;
	background: rgb(252, 249, 239);
}
.rightSidePosterImg {
	width: 100%;
	vertical-align: bottom;
	height: 100%;
}
.posterTypography {
	position: absolute;
	width: 60%;
	margin-top: 40px;
	margin-left: 40px;
}

.subImage {
	display: block;
	height: auto;
}


 
