@import "../../assets/scss/style.scss";
.tryIt-section{
    
    .tryIt-container{
        height: 357px;
        background-image: url("../../assets/images/Rectange-1.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .heading{
            font-family: GT Super Txt Trial;
            font-weight: 700;
            font-size: 40px;
            line-height: 52px;
            color: #FFFFFF;
        }
        .text{
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            color: #FFFFFF;
        }
    }
}