@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

// Remove Background Modal

.remove_container {
  height: 90vh !important;
  // border: 1px solid #DE6B32 !important;
  // padding: 5px;
  // border: 1px solid red;
  .span_text {
   
    color: #5F5F5F;
    padding-top: 6px;
    padding-left: 5px;
    height: 5vh;
    display: block;
    height: 8%;

  }
  .image_wrapper {
    padding: 10px;
    text-align: center;
    // margin-top: 1rem;
    height: 80%;
    border-top: 1px solid #DE6B32 !important;
    border-bottom: 1px solid #DE6B32 !important;
  }

  .remove_footer {
    height: 10%;
    // border: 1px solid red;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    // margin-top: 10px;

    .remove_btn {
      background: #DE6B32 !important;
      color: $white !important;
    }
    .remove_btn:hover {
      border: 1px solid #DE6B32 !important;
    }

    .keep_btn {
      border-color: #D9D9D9 !important;
      background: #D9D9D9 !important;
      color: #0F0F0F !important;
    }
  }
}

// preset modal
.preset_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  // text-align: center;
  align-items: center;

  .preset_input {
    border-color: $primary !important;
  }

  .submit_btn {
    background: $primary !important;
    width: 180px;
    color: $white !important;
  }
}

// batch successfully modal
.batch_success {
  height: 33vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  h1 {
    width: 85%;
    font-size: 23px;
    text-align: center;
  }

  hr {
    width: 60%;
    margin-top: 1.5rem;
  }

  .success_content {
    display: flex;
    gap: 20px;
    margin-top: 2rem;
    .download_btn {
      width: 157px;
      background: $primary;
      color: $white !important;
    }
    .dashboard_btn {
      width: 142px;
      background: #DE6B32;
      color: $white !important;
      font-size: 11px;
      border: 1px solid transparent;
    }
    .dashboard_btn:hover {
      border: none;
    }
  }
}
