@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

header {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  // z-index: 1000;
  background: #FFFFFF;
  // border-bottom:1px solid #DE6B32;
  box-shadow: 0px 4px 4px rgba(182, 182, 182, 1);
  height: 8vh;
  align-items: center;

  .header_logoContent {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 75%;

    .content_afterLogIn {
      display: flex;
      width: 100%;
      justify-content: center;
      .tabContent {
        display: flex;
        gap: 70px;
      }
    }
  }

  .header_authContent {
    display: flex;
    gap: 20px;

    .signInBtn {
      background: #DE6B32;
      color: $white !important;
      border-color: #DE6B32 !important;
      border-radius: 20px;
    }
    .signupBtn {
      color: #DE6B32 !important;
      border-color: #DE6B32 !important;
      border-radius: 20px;
    }
  }
}
