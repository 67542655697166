.presetBlock{
    width: 40%;
    height: 100px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    position: relative;
}

.closeIcon{
    position: absolute;
    top: 0;
    right: -3px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 100;
    margin: 0px;
    padding: 0px;
    // border: 1px solid red;
}


.presetImage{
    width: 100%;
    height: 80%;
    object-fit: contain;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.presetName{
    width: 100% !important;
    height: 20% !important;
    text-align: center;
    font-size: 0.6rem !important;
    font-weight: bold !important;
    line-height: 15px !important;
    font-family: 'Roboto', sans-serif !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}