@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&family=Roboto:wght@300;500;700;900&display=swap");

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 92vh;
  width: 100%;
  /* margin: auto; */
  max-width: 100%;
  /* border-top: 1px solid #DE6B32; */
  background-color: #EFEFEF;
  /* background-color:; */
  /* padding: 10px; */
  /* margin-top: 2rem; */
}
.presetBlock {
  width: 20%;
  /* height: 98%; */
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #EFEFEF;
  padding: 10px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}
.firstBlock {
  width: 57%;
  height: 98%;
  position: relative;
  
}
.secondBlock {
  width: 22%;
  /* height: 98%; */
  height: 100%;
  overflow-y: auto;
  border-left: 1px solid #EFEFEF;
  padding: 10px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}
.App {
  width: 100%;
  height: 100%;
  /* border: 2px dashed #6953d9; */
  background-color: #FAFAFA !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* display: none; */
}

.label  {
 
}

.cancelButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #D9D9D9 !important;
  border-radius: 0px;
  border: 1px solid #D9D9D9 !important;
  padding: 5px 10px;
}
.browseBlock {
  position: absolute;
  margin: auto;
  top: 30%;
  height: 30%;
  left: 38%;
  z-index: 20;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
/* input[type="file"] {
  display: none;
} */

.canvasClass {
  border: 1px solid darkslategray;
  pointer-events: none;
  /* background-color: #DE6B32; */
  /* position: relative !important; */
  
  /* max-width: 70%;
  max-height: 70%;
  background-color: white; */
  /* width: 363px !important;
  height: 324px !important;
  border-radius: 18px;
  position: absolute !important;
  left: 22% !important;
  top: 15% !important; */
}

.canvasClassUpload {
  /* border: 1px solid black; */
  width: 76% !important;
  height: 50vh!important;
  /* border-radius: 18px; */
  position: absolute !important;
  left: 13% !important;
  top: 0% !important;
  height: 68vh !important;
}

.uploadBlock {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.block {
  /* border: 1px solid black; */
  width: 100%;
}
.panelClass {
  font-family: "Roboto", sans-serif !important;
  color: #5F5F5F !important;
}

.collapsePanelClass{
  color: #5F5F5F !important;
}

.panelColor {
  background-color: #FFFFFF !important;
}

.centerAlign {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
}

.ant-form-item {
  margin-inline-end: 0px !important;
}

.formClass {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  margin: auto !important;
  width: 100% !important;
  padding-right: 0px !important;
  font-family: "Roboto", sans-serif;
}

.blockBody {
  width: 70%;
  margin: auto;
  margin-top: 20px;
}

.blockTitle {
  font-size: 1rem !important;
  font-weight: 700 !important;
  font-family: "Roboto", sans-serif;
}

.buttonBlock {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  margin-top: 10px;
  position: relative;
  border-top: 1px solid #D4D4D4;
  padding: 10px !important;
}

.clipButton {
  width: 80% !important;
  margin: auto !important;
}

.buttonClass {
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.8rem !important;
  color: "white !important";
  /* border-radius: px !important; */
  width: 80% !important;
  margin: 5px;
  height: 35px !important;
  cursor: pointer !important;
  
}

.orangeBackground{
  background-color: #DE6B32 !important;
}
.orangeBackground:hover{
  background-color: #DE6B32 !important;
}

.grayButon{
  background-color: #D9D9D9 !important;
  color:#0F0F0F !important;
  border-color: #D9D9D9 !important;
}
.grayButon:hover{
  background-color: #D9D9D9 !important;
  color:#0F0F0F !important;
  border-color: #D9D9D9 !important;
}

.orangeBlankButton{
  border-color: #DE6B32 !important;
  color:#DE6B32 !important;
}
.orangeBlankButton:hover{
  border-color: #DE6B32 !important;
  color:#DE6B32 !important;
}

.fontBlack {
  color: black !important;
}

.colorDiv {
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background: red;
  border: 1px solid gray;
}

.swatchDiv {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  /* width: 40px; */
  cursor: pointer;
  margin: 10px;
}
.popover {
  position: absolute;
  z-index: 20;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.swatchBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.verticalMiddle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.presets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  font-family: "Roboto", sans-serif;
}

.presetText {
  font-size: 1rem !important;
  font-weight: 600 !important;
  font-family: "Roboto", sans-serif !important;
  color: black !important;
}

.grayColor {
  color: #9b9b9b !important;
}
