@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.preset_legend {
  display: flex;
  flex-direction: column;
  // align-items: center;

  .preset_tab {
    margin-top: 1.5rem;
    .ant-segmented {
      width: 100%;
      background: $white;
    }
    .ant-segmented-group {
      gap: 15px;
    }
    .ant-segmented-item {
      background: $white;
      color: $black;
      border: 1px solid $black;
      width: 12vw;
      border-radius: 71px;
      padding: 6px;
    }
    .tab-btn {
      background: $white;
      color: $black;
      border-radius: 71px;
      width: 175px;
      text-align: center;
    }
    .ant-segmented-item-selected {
      background: #de6b32;
      color: $white;
      border: 1px solid #de6b32;
      width: 12vw;
      border-radius: 71px;
      padding: 6px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: $primary;
      color: $white;
      border-color: $primary !important;
      // border-bottom-width: 1px;

      border-radius: 71px;
      width: 175px;
      text-align: center;
    }
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):hover {
      color: #000000 !important;
    }
    .ant-radio-button-wrapper:focus-within {
      box-shadow: none !important;
    }
    .ant-radio-button-wrapper:hover {
      color: rgba(51, 51, 51, 0.65) !important;
    }
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):before {
      background: #ede4d1 !important;
    }
  }
  .preset_img {
    display: flex;
    gap: 20px;
    margin-top: 2rem;

    .preset_container {
      background: $white;
      width: 220px;
      height: 232px;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.05);
      position: relative;

      .preset_data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        font-size: 10px;
        /* justify-content: center; */
        align-items: center;
      }

      .preset_box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .image_cont {
      border: 1px solid #de6b32;
      // padding: 10px;
      border-radius: 10px;
      // height: 14rem;
      width: 9rem;
      background: $white;
      cursor: pointer;
    }
    .image_unSeleted {
      border: 1px solid rgba(51, 51, 51, 0.85);
      border-radius: 10px;
      cursor: pointer;
      margin-top: 10px;
      height: 244px;
  
    }
    .slick-list {
      // width: 50vw !important;
    }
    .slick-track {
      display: flex;
      gap: 20px;
    }
    .slick-slider {
      width: 83%;
    }
    .slick-prev {
      left: 93%;
      top: -40%;
    }
    .slick-next {
      right: 6px;
      top: -40%;
    }
  }
}
