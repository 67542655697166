@import "./mixin.scss";
@import "./variable.scss";

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: $roboto;
}
.linkText {
  @include link-text;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.color-primary {
  color: $primary;
}
.color-secondary {
  color: $deepBlue;
}
.colorRed {
  color: $red;
}
.f-500 {
  font-weight: 500;
}

.mt-1 {
  margin-top: 1vh;
}
.mt-2 {
  margin-top: 2vh;
}
.mt-3 {
  margin-top: 3vh;
}
.mt-4 {
  margin-top: 4vh;
}
.mb-1 {
  margin-bottom: 1vh;
}
.mb-2 {
  margin-bottom: 2vh;
}
.mb-3 {
  margin-bottom: 3vh;
}
.mb-4 {
  margin-bottom: 4vh;
}
.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mr-4 {
  margin-right: 24px;
}
.ml-1 {
  margin-left: 4px;
}
.ml-2 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-4 {
  margin-left: 24px;
}

.px-1 {
  padding: 0 4px;
}
.px-2 {
  padding: 0 8px;
}
.px-3 {
  padding: 0 12px;
}
.px-4 {
  padding: 0 24px;
}

// .py-1{
//   padding: 0 4px ;
// }
// .py-2{
//   padding: 0 8px ;
// }
// .py-3{
//   padding: 0 12px ;
// }
// .py-4{
//   padding: 0 24px ;
// }

.card-selected {
  border: 1px solid $deepBlue !important;
}
.display-none {
  display: none;
}
.display-hidden {
  visibility: hidden;
  pointer-events: none;
}
.text-center {
  text-align: center;
}

.form-spacing {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.d-flex {
  display: flex;
}
.flexBox {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
}
.ant-spin-spinning {
  color: black;
  margin-top: 10%;
}
.w-100 {
  width: 100%;
}
.icon-space {
  padding: 1rem;
  cursor: pointer;
}

.linkBtn {
  .linkTextBtn {
    color: #373737;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid transparent;
    padding: 10px 40px;
    border-radius: 32px;
    cursor: pointer;
  }
  .active-linkText {
    border: 1px solid #DE6B32;
    color: #373737;
    font-weight: 700 !important;
    padding: 10px 40px;
    border-radius: 32px;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
  }

  .linkTextBtn:hover {
    border: 1px solid #DE6B32;
    cursor: pointer;
  }
}

.proceed_btn {
  background: #DE6B32;
  color: $white;
  border-radius: 2px;
  width: 131px;
  height: 48px;
  :hover {
    background: #DE6B32 !important;
  }
}
.proceed_btn:hover
{
  background: #DE6B32 !important;
}

.checkbox_container {
  position: relative;
  top: -6px;
  left: 108px;
  padding: 2px;
}

.center_content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.custom-file-upload {
}

.ant-form-inline .ant-form-item {
  margin-inline-end: 0 !important;
}

.center-Card {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* justify-content: space-around; */
  gap: 57px;
  padding-left: 13rem;
  padding-top: 4rem;
  height: 51vh;
}

.trynow_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  // background: rgb(252, 249, 239);
  background: #FFFFFF;
  // border: 1px solid black;
}

.text_span {
  color: #939393;
  font-size: 12px;
  font-family: "Inter";
}

.custom_input {
  border-color:#DE6B32 ;
  padding: 1rem;
}

.dashboard_span {
  width: 10%;
  text-align: center;
}

.ant-modal .ant-modal-close-x {
  // color: black;
  // margin-top: -3rem;
  // margin-right: -5rem;
  font-size: 15px;

}
.get-started-btn{
  width: 168px;
  height: 49px;
  border-radius: 8px;
  background-color: #F5D1BF;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #292219;
  border: none;
  outline: none;
  &:hover{
      background-color: #fff;
      color: #251F17 !important;
  }

}

.text_container{
  display: flex;
  /* flex-direction: row-reverse; */
  width: 950px;
  padding: 1rem 0;
  margin: 0 auto;
}

.spacing-btw-component {
  margin-top: 12rem !important;
}
.common-container{
  padding: 0 150px;
}
.hr_bar {
  background-color: #de6b32 !important;
  height: 1px;
}

.gt-family {
  font-family: GT Super Txt Trial !important;

}


.color-orange {
  color: #de6b32;
}
.try_now_cont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.inter {
  font-family: $inter !important;
}

.batch_sign {
  height: 82px;
  /* background: pink; */
  display: flex;
  justify-content: center;
  align-items: end;
  font-weight: 600;
  color: rgb(71, 70, 70);
}

.color-black {
  color: rgb(71, 70, 70);
}

.login-modal {
  .ant-modal .ant-modal-close-x {
     margin-top: 0rem !important;
    margin-right: 0rem !important;
    font-size: 15px !important;
  }

  // height: 400px !important;
}



.google_btn {
  display: flex;
  background: rgba(236, 236, 236, 1) !important;
  width: 70%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 30px;
  gap: 15px;
  padding: 1.8rem 0 !important;

  span {
    color: rgba(43, 43, 43, 1) !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
}