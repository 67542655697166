.faq-section {
    .ant-collapse>.ant-collapse-item >.ant-collapse-header {
        padding: 36px 16px !important;
        align-items: center !important;
      }
      .ant-collapse-borderless >.ant-collapse-item {
        // border-bottom: none !important;
      }
  .faq-container {
    // padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .faq-subheading {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #de6b32;
    }
    .faq-heading {
      font-family: GT Super Txt Trial;
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
    }
    .collapse-section {
      width: 867px;
      margin: 40px 0;
      .ant-collapse-item {
        border-bottom: 1px solid #d9d9d9;
        
        .ant-collapse-expand-icon {
          svg {
            font-size: 20px;
            font-weight: 500;
          }
        }
        .ant-collapse-header-text {
          font-family: GT Super Txt Trial;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #251f17;
        }
        .ant-collapse-content-box {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #544e47;
          .box-text {
            padding-bottom: 12px;
            color: #544e47;
            font-weight: 300;
            ol {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

