@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";
.upload_container {
  // height: 372px;
  // width: 556px;
  // border-radius: 7px;

  .drag_dropCard {
    // height: 404px;
    // // width: 628px;
    // background: white;
    // box-shadow: 0px 0px 20.8676px #dde5f4;
    // border-radius: 7px;
    // margin-top: 1rem;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    .drag_content {
      border: 1px dashed #DE6B32;
      height: 295px;
      width: 282px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      // gap: 20px;
      align-items: center;
      justify-content: center;
    }
  }

  
}

.show_uploadImage {
  // height: 404px; */
  //  width: 546px;
  // background: white;
  // box-shadow: 0px 0px 20.8676px #dde5f4;
  // border-radius: 7px;
  // /* margin-top: 10px; */
  // /* padding: 20px; */
  width: 60%;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.total_files {
  padding: 0 13rem;
  text-align: end;
  margin-right: 3rem;
  padding-bottom: 2rem;
  

  span {
    color: #DE6B32;;
  }
}
