.features-section{
    .features-logo{
        // padding-top: 50px;
        text-align: center;
    }
    .features-heading{
        font-family: GT Super Txt Trial;
        font-weight: 700;
        font-size: 40px;
        line-height: 52px;
        text-align: center;
    }
    .features-container{
        width: 70%;
        margin: 0px auto;
        /* margin-top: 50px; */
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 100px;
        /* background: pink; */
        height: 500px;
        .img-section{
            width: 597px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // background-color: pink;
            // padding-top: 5rem;
            img{
                transition: 0.5s;
                width: 100%;
                // height: 70vh;
                object-fit: contain;
            }
           
            .background-text{
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                color: #251F17;
                transition: 0.3s;
                margin-bottom: 8px;

            }
            .background-img{
                width: 338.98px;
                height: 442px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                img{
                    // margin-top: 50px;
                    width: auto;
                }
            }
        }
        .hover-btn-section{
           display: flex;
           align-items: center;
           justify-content: center;
           flex-wrap: wrap;
           border-left: 2px solid #DE6B32;

            .hover-btn-container{
                width: 307px;
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                .hover-btn{
                    font-family: GT Super Txt Trial;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    color: #706B65;
                    padding: 0 0 15px 15px;
                    
                    &:hover{
                        cursor: pointer;
                    }
                    
    
                }
                .active-btn{
                    transition: 0.3s;
                    font-size: 24px;
                    font-family: GT Super Txt Trial;
                    font-weight: 700;
                    line-height: 36px;
                    color: #251F17;
                    padding: 0 0 15px 15px;
                    background: linear-gradient(358.79deg, rgba(252, 240, 235, 0.21) -21.69%, rgba(255, 168, 132, 0.25) 147.96%);
                    
                }
            }
           
        }
    }
}