@import '../../assets/scss/mixin.scss';
@import '../../assets/scss/variable.scss';

.hr_bar {
    background-color: $primary;
    height: 1px;
}
.info_under_logo {
    div:last-child {
        text-align: left;
    }
}
.email_col {
    float: right;
    label {
        font-weight: 700;
        font-size: 1rem;
    }
    .email_input {
        width: 350px;
        height: 40px;
        background-color: #f3f3f3;
        border-radius: 0;
    }
    .email_btn {
        margin: 0 18px;
        height: 40px;
        background-color: #f3f3f3;
        border-radius: 0;
    }
}
.country_box {
    align-items: center;
    .country_list {
        width: 40%;
        gap: 3% !important;
        .country_text {
            font-size: 1rem;
        }
    }
    .country_line {
        background-color: black;
        height: 1px;
        // width: 60%;
        flex-grow: 1;
    }
}
.address_text {
    text-align: left;
    white-space:pre;
    line-height: 35.036px;
}

.footer {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    .media_icon_container {
        margin-left: auto;
        margin-bottom: 5px;
        .social_media_icons {
            height: 25px;
            gap: 8px;
        }
    }
}
.call_box{
    .ant-row{
        background-color: #F8F8F8;
        height: 120px;
        padding: 0 50px;
        .call_box_title{
            font-weight: 800;
        }
        .inner_call_box{
            border-radius: 4px;
            height: 40px;
        }
        .btn_call_box{
            background-color: $primary;
            color: white;
        }
    }
}
.link_text{
    color: black !important;
}
.footer_list_text{
    cursor: pointer;
}