@import "react-alice-carousel/lib/scss/alice-carousel.scss";
.testimonial-section {
  //   padding: 50px 0;
  .testimonial-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .testimonial-left-side {
      display: flex;
      width: 80%;

      align-items: center;
      //   gap: 3rem;
      .strip {
        height: 346px;
        background-color: #fff2ec;
        width: 200px;
      }
      .card_testimonial {
        // width: 55%;
        height: 473px;
        border-radius: 23px;
        background: #de6b32;
        text-align: initial;
        // padding: 3rem;
        padding-left: 4rem;
        display: flex;
        flex-direction: column;
        gap: 27px;
        justify-content: center;
        // text-align: justify;

        p {
          color: white;
          font-weight: 400;
          width: 374px;
        }
        .card_header {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }
    }
    .testimonial-right-side {
      /* align-items: center; */
    //   background: linear-gradient(
    //     260deg,
    //     rgba(222, 107, 50, 0.12) 50%,
    //     hsla(23, 36%, 96%, 0)
    //   );
      display: flex;
      flex-direction: column;
      height: 346px;
      background-color: #fff2ec;

      justify-content: center;
      /* padding: 50px; */
      text-align: left;
      text-align: initial;
      width: 100%;
      padding-left: 140px;
      .sub-heading {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #de6b32;
      }
      .heading {
        font-family: GT Super Txt Trial;
        font-weight: 700;
        font-size: 40px;
        line-height: 52px;
        color: #000000;
      }
    }
  }
}
