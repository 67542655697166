.alignParentBlock{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 90%;
    height: 8vw;
    border: 1px solid  #F7A18380;
    border-radius: 8px;
    font-size: 0.8vw;
    margin: auto;
}

.alignParentSideChild{
    width: 35%;
    /* border: 1px solid red; */
    height: 100%;
    display: flex;
}
.sideChildContent{
    align-self: center;
    /* border: 1px solid red; */
    width: 100%;
    height: 20%;
    display: flex;
    align-content: flex-start;
    flex-direction: column;
}

.atomBlock{
    width: 100%;
    height: 50%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
}

.marginClass{
    border: 1px solid #F7A18380 !important;
    border-radius: 3px;
    width: 3.5rem;
    height: 1.5vw;
    text-align: center;
}

.alignParentCenterChild{
    width: 50%;
    /* border: 1px solid green; */
    height: 100%;
    display: flex;
    flex-direction: column;
}

.alignmentSelection{
    width: 100%;
    height: 60%;
    border: 1px solid  #F7A18380;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* align-content: space-around; */
}

.alignSection{
    border: 0.4px solid #A1A1A1;
    width: 70%;
    text-align: center;
    /* height: 25%; */
    color: #949494;
    cursor: pointer;
}

.alignSection_selected{
    border: 0.4px solid #A1A1A1;
    width: 70%;
    font-weight: bold;
    text-align: center;
    background-color:  #DFEAF1;
    /* height: 25%; */
    color: black;
    cursor: pointer;

}




.topMarginBlock{
    width: 100%;
    height: 35%;
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
}


.topMarginInputBlock{
    /* border: 1px solid red; */
    display: flex;
    justify-content: left;
    align-items: center;
}

.topMarginTextBlock{
    width: 20%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
